import React, {
    useMemo
} from "react";

import pin from "../../../img/pin.png";

function OpenCallCard({ openCall, onClick, showPin }) {
    const onClickInternal = useMemo(() => {
        if(!onClick) {
            return undefined;
        }
        return () => {
            onClick(openCall);
        }
    }, [openCall, onClick]);
    return (
        <div className="card card-stickynote" onClick={ onClickInternal } style={{ cursor: onClickInternal && "pointer" }}>
            { showPin && (
                <img src={ pin } className="card-pin" alt="Pin"/>
            )}
            <div className="card-body">
                <h2 className="card-title mb-0">
                    { openCall.projectTitle }
                </h2>
                <h3 className="card-title text-muted">
                    { openCall.roleName }
                </h3>
                <p className="card-text">
                    Posted by @{ openCall.author.username }
                </p>
            </div>
        </div>
    )
}

export default React.memo(OpenCallCard);
