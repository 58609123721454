import React from "react";
import {
    Container
} from "react-bootstrap";

import Helmet from "../components/Helmet";
import Background from "../components/Background";

function Page404() {
    return (
        <React.Fragment>
            <Background color="#c8f5c4"/>
            <Helmet title="404 Not Found"/>

            <Container>
                <div className="text-center my-5">
                    <h1 style={{ fontSize: "4rem" }}>
                        404 Not Found
                    </h1>
                </div>
            </Container>

        </React.Fragment>
    )
}

export default React.memo(Page404);
