import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    useMatch,
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Page404 from "../../Page404";
import Loading from "../../../components/Loading";
import Background from "../../../components/Background";
import Helmet from "../../../components/Helmet";

function ArtistFilesDetail() {
    const match = useMatch("/artist-files/:username");
    const username = useMemo(() => {
        return match.params.username;
    }, [match]);

    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!username) {
            return;
        }
        axios.post("/getUser", { username })
            .then((response) => {
                if(response.data.valid) {
                    setUser(response.data.user);
                } else {
                    setError("An error occured. Please try again later. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("An error occured. Please try again later.");
            });
    }, [username]);

    if(error) {
        return (
            <div className="container">
                <Alert variant="danger">{ error }</Alert>
            </div>
        )
    }
    if(!username) {
        return (
            <Page404/>
        )
    }
    if(!user) {
        return (
            <Loading/>
        )
    }
    return (
        <div className="container">
            <Background color="#fcefd6"/>
            <Helmet title={ user.name }/>
            <div className="text-center my-5">
                <h1>
                    { user.name }
                </h1>
            </div>
            <div className="row">

                <div className="col-md-3">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h2 className="card-title">
                                @{ user.username }
                            </h2>
                            <p className="card-text">
                                Birthday:
                            </p>
                            <p className="card-text">
                                Based:
                            </p>
                            <p className="card-text">
                                TikTok:
                            </p>
                            <p className="card-text">
                                Instagram:
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col">

                    <div className="row">

                        <div className="col-md-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h2 className="card-title">
                                        Medium
                                    </h2>
                                    <p className="card-text">
                                        Lorem ipsum, dolor sit amet, consectetur adipiscing
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h2 className="card-title">
                                        Muses
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h2 className="card-title">
                                        Chats
                                    </h2>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <h2 className="card-title">
                                Who Am I?
                            </h2>
                            <p className="card-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <h2 className="card-title">
                                Collaborations
                            </h2>
                            <p className="card-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default React.memo(ArtistFilesDetail);
