import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Form,
    Modal
} from "react-bootstrap";

class NewOpenCallModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,

            projectTitle: "",
            roleName: "",
            description: "",
            date: moment().format("YYYY-MM-DD")
        };
        this.create = this.create.bind(this);
    }

    create() {
        this.setState({ loading: true, error: null });
        axios.post("/addOpenCall", {
            projectTitle: this.state.projectTitle,
            roleName: this.state.roleName,
            description: this.state.description,
            date: this.state.date
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onSave();
                    this.props.handleClose();
                } else {
                    this.setState({ error: "An error occured, please try again later. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ error: "An error occured, please try again later." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1 className="mb-0">
                            Create Open Call
                        </h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <Form.Group controlId="projectTitle" className="mb-2">
                        <Form.Label>Project Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={ this.state.projectTitle }
                            onChange={ (event) => this.setState({ projectTitle: event.target.value }) }
                            disabled={ this.state.loading }
                        />
                    </Form.Group>
                    <Form.Group controlId="roleName" className="mb-2">
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={ this.state.roleName }
                            onChange={ (event) => this.setState({ roleName: event.target.value }) }
                            disabled={ this.state.loading }
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            type="text"
                            value={ this.state.description }
                            onChange={ (event) => this.setState({ description: event.target.value }) }
                            disabled={ this.state.loading }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={ this.create } disabled={ this.state.loading }>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default NewOpenCallModal;
