import React from "react";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";

import ScrollToTop from "./hooks/ScrollToTop";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Account from "./pages/auth/Account";
import Page404 from "./pages/Page404";
import ArtistFilesDetail from "./pages/artist-files/detail/ArtistFilesDetail";
import ArtistFiles from "./pages/artist-files/ArtistFiles";
import OpenCalls from "./pages/open-calls/OpenCalls";
import BrainStorm from "./pages/brain-storm/BrainStorm";
import CreateDate from "./pages/create-date/CreateDate";

function SiteRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Navbar/>
            <div className="site-content d-flex flex-column">
                <Routes>
                    <Route path="/" element={ <Home/> }/>
                    <Route path="/login" element={ <Login/> }/>
                    <Route path="/register" element={ <Register/> }/>
                    <Route path="/account" element={ <Account/> }/>
                    <Route path="/artist-files/:username" element={ <ArtistFilesDetail/> }/>
                    <Route path="/artist-files" element={ <ArtistFiles/> }/>
                    <Route path="/open-calls" element={ <OpenCalls/> }/>
                    <Route path="/brain-storm" element={ <BrainStorm/> }/>
                    <Route path="/create-date" element={ <CreateDate/> }/>
                    <Route path="*" element={ <Page404/> }/>
                </Routes>
            </div>
            <Footer/>
        </BrowserRouter>
    )
}

export default React.memo(SiteRouter);
