import React from "react";
import {
    Link
} from "react-router-dom";

function ProfileCard({ user }) {
    return (
        <div className="d-flex flex-row mb-3">
            <div
                className="d-flex justify-content-center align-items-center text-muted rounded"
                style={{
                    backgroundColor: "lightgray",
                    width: "150px",
                    height: "150px",
                    fontSize: "2rem"
                }}
            >
                <div>
                    <i className="fas fa-user"/>
                </div>
            </div>
            <div className="card flex-grow-1 ms-3">
                <div className="card-body">
                    <div className="d-flex flex-row">
                        <div className="flex-grow-1">
                            <h2>
                                { user.name }
                            </h2>
                            <p className="card-text text-muted">
                                @{ user.username }
                            </p>
                            <p className="card-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit...
                            </p>
                        </div>
                        <div>
                            <Link
                                to={ "/artist-files/" + user.username }
                                className="btn btn-primary"
                            >
                                View profile
                                <i className="fas fa-caret-right ms-2"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ProfileCard);
