import React, {
    useMemo
} from "react";
import {
    Container
} from "react-bootstrap";
import ReactPlayer from "react-player";

import Background from "../../components/Background";
import Helmet from "../../components/Helmet";
import useWindowSize from "../../hooks/WindowSize";

// import background from "../../img/backgrounds/create-date.png";
// import createDate from "../../img/create-date/create-date.png";

function CreateDate() {
    const windowSize = useWindowSize();

    const playerSize = useMemo(() => {
        const maxHeightForWidth = windowSize.width / 16 * 9;
        const heightAvailable = windowSize.height - 84;
        let height = Math.min(maxHeightForWidth, heightAvailable);
        let width = height / 9 * 16;

        return { width, height };
    }, [windowSize]);

    return (
        <React.Fragment>
            <Background color="#000127"/>
            <Helmet title="Create Date"/>

            <Container fluid>
                {/*<div className="d-flex justify-content-center px-5">*/}
                {/*    <div className="my-5" style={{ maxWidth: "300px" }}>*/}
                {/*        <h1>*/}
                {/*            <img*/}
                {/*                src={ createDate }*/}
                {/*                alt="Create Date"*/}
                {/*                className="img-fluid"*/}
                {/*            />*/}
                {/*        </h1>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="d-flex justify-content-center">
                    <ReactPlayer
                        url="https://youtu.be/2Zon-w1mOak"
                        height={ playerSize.height }
                        width={ playerSize.width }
                        config={{
                            youtube: {
                                playerVars: {
                                    showinfo: 1,
                                    autoplay: 1
                                }
                            }
                        }}
                    />
                </div>

                {/*<div className="create-date-container">*/}
                {/*    <div className="loading">*/}
                {/*        <div className="loading-text-container">*/}
                {/*            <h2>*/}
                {/*                Creating a date...*/}
                {/*            </h2>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </Container>
        </React.Fragment>
    )
}

export default React.memo(CreateDate);
