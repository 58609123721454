import React, {
    createContext
} from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";

const AuthenticationContext = createContext(null);

export class AuthenticationManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            getSession: this.getSession.bind(this),
            login: this.login.bind(this),
            register: this.register.bind(this),
            logout: this.logout.bind(this)
        }
    }

    componentDidMount() {
        this.getSession();
    }

    setUser(user) {
        if(user) {
            console.log("Logged in as user " + user.name + ".");
            Sentry.setUser({
                id: user.id,
                name: user.name,
                email: user.email
            });
        }
        this.setState({ user });
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "INVALID_PARAMETERS":
                return "Invalid input.";
            case "INVALID_CREDENTIALS":
                return "Invalid username or password.";
            case "PASSWORD_INSECURE":
                return "Your password isn't strong enough.";
            case "PASSWORD_MISMATCH":
                return "The passwords don't match.";
            case "INVALID_EMAIL":
                return "The entered email isn't a valid e-mail address.";
            case "EMAIL_IN_USE":
                return "An account already exists with this e-mail address.";
            default:
                return "Something went wrong. Please try again later.";
        }
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                }
            })
            .catch((error) => {
                console.error(error);
                this.setUser(null);
            });
    }

    login(state, onError) {
        const {
            email,
            password
        } = state;

        if(email.length === 0 || password.length === 0) {
            onError(this.getErrorMessage("INVALID_CREDENTIALS"));
            return;
        }
        axios.post("/login", { email, password, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    onError(this.getErrorMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getErrorMessage());
            });
    }

    register(state, onError) {
        const {
            name,
            username,
            email,
            password,
            passwordConfirm
        } = state;

        if(name.length === 0 || username.length === 0 || password.length === 0) {
            onError(this.getErrorMessage("INVALID_PARAMETERS"));
            return;
        }
        axios.post("/register", { name, username, email, password, passwordConfirm, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    onError(this.getErrorMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getErrorMessage());
            });
    }

    logout() {
        axios.get("/logout")
            .then((response) => {
                setTimeout(() => {
                    if(response.data.valid) {
                        this.setUser(null);
                    } else {
                        // TODO: Implement error handling.
                    }
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                // TODO: Implement error handling.
            });
    }

    render() {
        return (
            <AuthenticationContext.Provider value={ this.state }>
                { this.props.children }
            </AuthenticationContext.Provider>
        )
    }

}

export default AuthenticationContext;
