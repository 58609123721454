import React from "react";

function Title({ children }) {
    return (
        <div className="d-flex w-100 justify-content-center my-3 card-title-container">
            <div className="card">
                <div className="card-body">
                    { children }
                </div>
            </div>
        </div>
    )
}

export default React.memo(Title);
