import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link,
    matchPath,
    useLocation
} from "react-router-dom";
import {
    Button,
    Container,
    Nav,
    Navbar as RBNavbar,
    NavDropdown
} from "react-bootstrap";

import AuthenticationContext from "../../context/AuthenticationManager";

import logo from "../../img/logo.png";

function NavbarLink({to, title, hidden = false, onClick}) {
    const { pathname } = useLocation();
    const match = matchPath(to, pathname);
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    const authentication = useContext(AuthenticationContext);

    const [showCollapsed, setShowCollapsed] = useState(false);
    const collapse = useMemo(() => {
        return () => {
            setShowCollapsed(false);
        };
    }, []);

    const onClickLogout = useMemo(() => {
        return () => {
            authentication.logout();
            collapse();
        }
    }, [authentication, collapse]);

    return (
        <React.Fragment>
            <RBNavbar bg="dark" variant="dark" expand="lg" fixed="top" expanded={ showCollapsed }>
                <Container>
                    <RBNavbar.Brand href="/">
                        <img
                            src={ logo }
                            className="navbar-logo"
                            alt="MuseMate"
                        />
                    </RBNavbar.Brand>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) }/>
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavbarLink to="/" title="Home" onClick={ collapse }/>
                            <NavbarLink to="/open-calls" title="Open Call" onClick={ collapse }/>
                            <NavbarLink to="/brain-storm" title="Brain Storm" onClick={ collapse }/>
                            <NavbarLink to="/create-date" title="Create Date" onClick={ collapse }/>
                            <NavbarLink to="/artist-files" title="Artist Files" onClick={ collapse }/>
                            { authentication.user ? (
                                <NavDropdown
                                    title={ <i className="fas fa-user"/> }
                                    id="account"
                                    align="end"
                                    className="mr-4"
                                >
                                    <Link to={ "/artist-files/" + authentication.user.username } className="dropdown-item" onClick={ collapse }>
                                        My Artist Files
                                    </Link>
                                    <Link to="/account" className="dropdown-item" onClick={ collapse }>
                                        Account
                                    </Link>
                                    <Button className="dropdown-item text-danger" style={{ borderRadius: 0 }} onClick={ onClickLogout }>
                                        Logout
                                    </Button>
                                </NavDropdown>
                            ) : (
                                <div>
                                    <Link to="/register" className="btn btn-secondary ms-lg-2 my-1 my-lg-0" onClick={ collapse }>
                                        Register
                                    </Link>
                                    <Link to="/login" className="btn btn-primary ms-2 my-1 my-lg-0" onClick={ collapse }>
                                        Login
                                    </Link>
                                </div>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>
            <div className="navbar-spacer"/>
        </React.Fragment>
    )
}

export default React.memo(Navbar);
