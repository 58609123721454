import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";

import imageOpenCall from "../img/home/open-call.png";
import imageCreateDate from "../img/home/create-date.png";
import imageCyberShop from "../img/home/cyber-shop.png";
import imageBrainStorm from "../img/home/brain-storm.png";
import imageArtistFiles from "../img/home/artist-files.png";

function HomeLink({ to, image, title, external = false, showTitle }) {
    const createLink = useMemo(() => {
        return (children) => {
            if(external) {
                return (
                    <a
                        href={ to }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="home-link"
                    >
                        { children }
                    </a>
                )
            } else {
                return (
                    <Link
                        to={ to }
                        className="home-link"
                    >
                        { children }
                    </Link>
                )
            }
        }
    }, [to, external]);
    return (
        <div className="home-link">
            { createLink(
                <React.Fragment>
                    <img
                        src={ image }
                        alt={ title }
                    />
                    { showTitle && (
                        <p>
                            { title }
                        </p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

function HomeLinks() {
    return (
        <div className="home-link-container">
            <HomeLink
                to="/open-calls"
                title="Open Call"
                image={ imageOpenCall }
            />
            <HomeLink
                to="/create-date"
                title="Create Date"
                image={ imageCreateDate }
            />
            <HomeLink
                to="https://cyber-atelier.art"
                title="Cyber Shop"
                image={ imageCyberShop }
                external
            />
            <HomeLink
                to="/brain-storm"
                title="Brain Storm"
                image={ imageBrainStorm }
            />
            <HomeLink
                to="/artist-files"
                title="Artist Files"
                image={ imageArtistFiles }
            />
        </div>
    )
}

export default React.memo(HomeLinks);
