import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link,
    Navigate
} from "react-router-dom";
import {
    Alert,
    Button,
    Container,
    Spinner
} from "react-bootstrap";

import AuthenticationContext from "../../context/AuthenticationManager";
import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import Title from "../../components/layout/Title";
import Background from "../../components/Background";

function Login() {
    const authenticationContext = useContext(AuthenticationContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
            setLoading(false);
        }
    }, []);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            authenticationContext.login({ email, password }, onError);
        }
    }, [authenticationContext, email, password, onError]);

    if(authenticationContext.user === undefined) {
        return (
            <Loading/>
        )
    }
    if(authenticationContext.user !== null) {
        return (
            <Navigate to="/account" replace/>
        )
    }

    return (
        <React.Fragment>
            <Background color="#c8f5c4"/>
            <Helmet title="Login"/>

            <Container>
                <Title>
                    <h1>Login</h1>
                </Title>
                <div className="d-flex w-100 justify-content-center">
                    <div style={{ maxWidth: "400px" }}>
                        { error && (
                            <Alert variant="danger">{ error }</Alert>
                        )}
                        <form onSubmit={ onSubmit }>
                            <div className="form-group mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="E-mail"
                                    disabled={ loading }
                                    value={ email }
                                    onChange={(event) => setEmail(event.target.value) }
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Password"
                                    disabled={ loading }
                                    value={ password }
                                    onChange={(event) => setPassword(event.target.value) }
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={ loading }
                                >
                                    { loading && (
                                        <Spinner animation="border" variant="light" size="sm" className="me-2"/>
                                    )}
                                    Login
                                </Button>
                            </div>
                        </form>
                        <Link to="/register" className="btn btn-link mt-3">
                            Don't have an account yet? Create one!
                        </Link>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default React.memo(Login);
