import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({ title = null, description = null }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? "MuseMate" : (title + " - MuseMate") }
            </title>
            <meta name="description" content={ description } />
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
