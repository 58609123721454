import React, {
    useContext
} from "react";
import OpenCallCard from "./OpenCallCard";
import NewOpenCallCard from "./NewOpenCallCard";
import AuthenticationContext from "../../../context/AuthenticationManager";

function OpenCallsCards({ openCalls, onOpenCallClick, onNewClick }) {
    const auth = useContext(AuthenticationContext);
    return (
        <div className="row stickynotes-container">
            { openCalls.map((openCall) => (
                <div className="col-md-4 mb-4 stickynote" key={ openCall.id }>
                    <OpenCallCard
                        openCall={ openCall }
                        onClick={ onOpenCallClick }
                        key={ openCall.id }
                    />
                </div>
            ))}
            { auth.user && (
                <div className="col-md-4 mb-4 stickynote">
                    <NewOpenCallCard
                        onClick={ onNewClick }
                    />
                </div>
            )}
        </div>
    );
}

export default React.memo(OpenCallsCards);
