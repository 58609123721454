import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../components/Loading";
import OpenCallModal from "./components/OpenCallModal";
import Helmet from "../../components/Helmet";
import OpenCallsCards from "./components/OpenCallsCards";
import Background from "../../components/Background";

import background from "../../img/backgrounds/open-calls.jpg";
import logo from "../../img/open-calls/open-calls.png";
import NewOpenCallModal from "./components/NewOpenCallModal";

function OpenCalls() {
    const [openCalls, setOpenCalls] = useState(null);
    const [error, setError] = useState(null);

    const [selectedOpenCall, setSelectedOpenCall] = useState(null);
    const [showOpenCallModal, setShowOpenCallModal] = useState(false);
    const [showNewOpenCallModal, setShowNewOpenCallModal] = useState(false);

    const refreshOpenCalls = useMemo(() => {
        return () => {
            setOpenCalls(null);
            setError(null);
            axios.get("/getOpenCalls")
                .then((response) => {
                    if(response.data.valid) {
                        setOpenCalls(response.data.openCalls);
                    } else {
                        setError("An error occured, please try again later. (" + response.data.error + ")");
                    }
                })
                .catch((requestError) => {
                    console.error(requestError);
                    setError("An error occured, please try again later.");
                });
        }
    }, []);
    useEffect(() => {
        refreshOpenCalls();
    }, [refreshOpenCalls]);

    const onOpenCallClick = useMemo(() => {
        return (openCall) => {
            setSelectedOpenCall(openCall);
            setShowOpenCallModal(true);
        }
    }, []);
    const onNewOpenCallClick = useMemo(() => {
        return () => {
            setShowNewOpenCallModal(true);
        }
    }, []);
    const handleModalClose = useMemo(() => {
        return () => {
            setShowOpenCallModal(false);
            setShowNewOpenCallModal(false);
        }
    }, []);

    return (
        <div className="container">
            <Background image={ background }/>
            <Helmet title="Open Calls"/>

            <div className="d-flex justify-content-center px-4">
                <div className="my-5" style={{ maxWidth: "400px" }}>
                    <h1>
                        <img
                            src={ logo }
                            alt="Open Call"
                            className="img-fluid"
                        />
                    </h1>
                </div>
            </div>

            <OpenCallModal
                openCall={ selectedOpenCall }
                show={ showOpenCallModal }
                handleClose={ handleModalClose }
                onRemove={ refreshOpenCalls }
            />
            <NewOpenCallModal
                show={ showNewOpenCallModal }
                onSave={ refreshOpenCalls }
                handleClose={ handleModalClose }
            />

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !openCalls ? (
                <Loading/>
            ) : (
                <OpenCallsCards
                    openCalls={ openCalls }
                    onOpenCallClick={ onOpenCallClick }
                    onNewClick={ onNewOpenCallClick }
                />
            )}
        </div>
    )
}

export default React.memo(OpenCalls);
