import React from "react";
import {
    Container
} from "react-bootstrap";

import Background from "../../components/Background";
import Helmet from "../../components/Helmet";

import background from "../../img/backgrounds/brain-storm.png";
import brainStorm from "../../img/brain-storm/brain-storm.png";

function BrainStorm() {
    return (
        <React.Fragment>
            <Background image={ background }/>
            <Helmet title="Brain Storm"/>

            <Container fluid>
                <div className="d-flex justify-content-center px-5">
                    <div className="my-5" style={{ maxWidth: "500px" }}>
                        <h1>
                            <img
                                src={ brainStorm }
                                alt="Brain Storm"
                                className="img-fluid"
                            />
                        </h1>
                    </div>
                </div>

            </Container>
        </React.Fragment>
    )
}

export default React.memo(BrainStorm);
