import React, {
    useMemo
} from "react";

function Background({ image, color }) {
    const style = useMemo(() => {
        let style = {};
        if(image) {
            style = {
                ...style,
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            };
        }
        if(color) {
            style = {
                ...style,
                backgroundColor: color
            };
        }
        return style;
    }, [image, color]);
    return (
        <div
            className="background-apply"
            style={ style }
        />
    )
}

export default React.memo(Background);
