import React from "react";

import {
    AuthenticationManager
} from "./context/AuthenticationManager";
import SiteRouter from "./SiteRouter";

function App() {
    return (
        <AuthenticationManager>
            <SiteRouter/>
        </AuthenticationManager>
    );
}

export default React.memo(App);
