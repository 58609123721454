import React, {
    useContext,
    useState
} from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";

import RemoveOpenCallModal from "./RemoveOpenCallModal";
import AuthenticationContext from "../../../context/AuthenticationManager";

function OpenCallModal({ openCall, show, handleClose, onRemove }) {
    const auth = useContext(AuthenticationContext);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    console.log(auth);
    if(!openCall) {
        return null;
    }
    return (
        <React.Fragment>
            <RemoveOpenCallModal
                openCall={ openCall }
                show={ showRemoveModal }
                handleClose={ () => {
                    setShowRemoveModal(false);
                    handleClose();
                }}
                onRemove={ onRemove }
            />
            <Modal show={ show && !showRemoveModal } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="d-flex flex-column">
                            <h1 className="mt-1">
                                { openCall.projectTitle }
                            </h1>
                            <h2 className="text-muted">
                                { openCall.roleName }
                            </h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        { openCall.description }
                    </p>
                    <p className="mb-0">
                        Posted by
                        <Link
                            to={ "/artist-files/" + openCall.author.username }
                            className="ms-1"
                        >
                            @{ openCall.author.username }
                        </Link>
                        <span className="mx-3">
                            -
                        </span>
                        Project date:
                        <span className="ms-1">
                            { moment(openCall.date).format("DD-MM-YYYY") }
                        </span>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    { auth.user && auth.user.id === openCall.author.id && (
                        <Button variant="danger" onClick={ () => setShowRemoveModal(true) }>
                            Remove
                        </Button>
                    )}
                    <div className="flex-grow-1"/>
                    <Button variant="secondary" onClick={ handleClose }>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default React.memo(OpenCallModal);
