import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../components/Loading";
import Title from "../../components/layout/Title";
import ProfileCard from "./components/ProfileCard";
import Background from "../../components/Background";
import Helmet from "../../components/Helmet";

function ArtistFiles() {
    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setUsers(null);
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    setUsers(response.data.users);
                } else {
                    setError("An error occured, please try again later. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("An error occured, please try again later.");
            });
    }, []);

    return (
        <div className="container">
            <Background color="#fcefd6"/>
            <Helmet title="Artist Files"/>
            <Title>
                <h1>Artist Files</h1>
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !users ? (
                <Loading/>
            ) : users.map((user) => (
                <ProfileCard
                    user={ user }
                    key={ user.id }
                />
            ))}
        </div>
    )
}

export default React.memo(ArtistFiles);
