import React, {
    createRef,
    useLayoutEffect,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";

import useWindowSize from "../../hooks/WindowSize";

function Footer() {
    let footerRef = createRef();
    const [height, setHeight] = useState(0);
    const size = useWindowSize();

    useLayoutEffect(() => {
        if (footerRef.current) {
            setHeight(footerRef.current.offsetHeight);
        }
    }, [footerRef, size]);

    const firstYear = 2023;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }
    return (
        <React.Fragment>
            <div style={{ height: (height + 100) + "px" }}/>
            <div className="jumbotron jumbotron-fluid footer mb-0">
                <Container>
                    <Row>
                        <Col md="4" className="my-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/privacypolicy" className="nav-link active footer-link">
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="jumbotron jumbotron-fluid footer-secondary mb-0 py-4">
                <Container className="text-white">
                    <div className="d-flex flex-column flex-lg-row align-items-md-center">
                        <div className="flex-lg-grow-1 text-center text-lg-left text-muted">
                            &copy; { yearString } SR Productions.
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);
