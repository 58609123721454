import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import axios from "axios";
import { stringify } from "qs";

import "./scss/index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://e763cd91576c4113ab5abfaf75242cf9@sentry1.zsnode.com/56",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });
}

if(development) {
    axios.defaults.baseURL = "/v1/";
} else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/v1/" : "https://api.musemate.art/v1/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [(data) => {
    data = stringify(data);
    return data;
}];

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
