import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

class RemoveOpenCallModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,

            projectTitle: "",
            roleName: "",
            description: "",
            date: moment().format("YYYY-MM-DD")
        };
        this.remove = this.remove.bind(this);
    }

    remove() {
        this.setState({ loading: true, error: null });
        axios.post("/removeOpenCall", {
            openCallId: this.props.openCall.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onRemove();
                    this.props.handleClose();
                } else {
                    this.setState({ error: "An error occured, please try again later. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ error: "An error occured, please try again later." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1 className="mb-0 text-danger">
                            Remove Open Call
                        </h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <div className="text-danger">
                        <b>
                            Are you sure you want to remove this Open Call? It cannot be restored!
                        </b>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={ this.remove } disabled={ this.state.loading }>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default RemoveOpenCallModal;
