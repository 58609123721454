import React, {
    useContext
} from "react";
import {
    Navigate
} from "react-router-dom";
import {
    Button,
    Container
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import AuthenticationContext from "../../context/AuthenticationManager";
import Loading from "../../components/Loading";
import Title from "../../components/layout/Title";
import Background from "../../components/Background";

function Account() {
    const authenticationContext = useContext(AuthenticationContext);
    if(authenticationContext.user === undefined) {
        return (
            <Loading/>
        );
    }
    if(authenticationContext.user === null) {
        return (
            <Navigate to="/login" replace/>
        )
    }
    return (
        <React.Fragment>
            <Background color="#c8f5c4"/>
            <Helmet title="Account"/>

            <Container className="text-center">
                <Title>
                    <h1>Account</h1>
                </Title>
                <h2>
                    Welcome { authenticationContext.user.name }
                </h2>
                <Button variant="secondary" onClick={ authenticationContext.logout }>
                    Logout
                </Button>
            </Container>
        </React.Fragment>
    )
}

export default React.memo(Account);
