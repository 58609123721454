import React from "react";

import pin from "../../../img/pin.png";

function NewOpenCallCard({ onClick, showPin }) {
    return (
        <div className="card card-stickynote" onClick={ onClick } style={{ cursor: onClick && "pointer" }}>
            { showPin && (
                <img src={ pin } className="card-pin" alt="Pin"/>
            )}
            <div className="card-body">
                <h2 className="card-title text-center">
                    New Open Call
                </h2>
                <h3 className="card-title text-muted text-center mb-0">
                    <i className="fas fa-plus"/>
                </h3>
            </div>
        </div>
    )
}

export default React.memo(NewOpenCallCard);
