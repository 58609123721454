import React from "react";
import {
    Container
} from "react-bootstrap";

import Helmet from "../components/Helmet";
import HomeLinks from "../components/HomeLinks";
import Background from "../components/Background";

import landscape from "../img/backgrounds/landscape.png";
import home from "../img/home/home-logo.png";

function Home() {
    return (
        <React.Fragment>
            <Background image={ landscape }/>
            <Helmet/>

            <Container fluid>
                <div className="d-flex justify-content-center px-5">
                    <div className="my-5" style={{ maxWidth: "300px" }}>
                        <h1>
                            <img
                                src={ home }
                                alt="Home"
                                className="img-fluid"
                            />
                        </h1>
                    </div>
                </div>

                <HomeLinks/>
            </Container>
        </React.Fragment>
    )
}

export default React.memo(Home);
